var mySwiper = new Swiper('.home-banner .swiper-container', {
    loop: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
      // dynamicBullets: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
    },
    on: {
        init: function() {
            swiperAnimateCache(this); //隐藏动画元素 
            swiperAnimate(this); //初始化完成开始动画
        },
        slideChangeTransitionEnd: function() {

            swiperAnimate(this); //每个slide切换结束0时也运行当前slide动画
            //this.slides.eq(this.activeIndex).find('.ani').removeClass('ani'); 动画只展现一次，去除ani类名
        }
    },

})
 mySwiper.el.onmouseover = function() {
                mySwiper.autoplay.stop();
            }

            //鼠标离开开始自动切换
 mySwiper.el.onmouseout = function() {
   mySwiper.autoplay.start();
 }
var swiper1 = new Swiper('.video-clips .swiper-container', {
    autoplay: {
        delay: 3000,
        disableOnInteraction: false,
    },
    speed: 700,
    allowTouchMove: false,
    lazy: {
        loadPrevNext: true,
        loadPrevNextAmount: 3,
    },
    centeredSlides: true,
    spaceBetween: 24,
    loop: true,
    slidesPerView: 'auto',
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});
window.onresize = function() {
    swiper1.update();

}
 swiper1.el.onmouseover = function() {
                swiper1.autoplay.stop();
            }

            //鼠标离开开始自动切换
 swiper1.el.onmouseout = function() {
   swiper1.autoplay.start();
 }
var mySwiper1 = new Swiper('.product-video .swiper-container', {

    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    centeredSlides: true,
    spaceBetween: 20,
    slidesOffsetBefore: 40,
    loop: true,
    slidesPerView: 'auto',

    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
})

           var MySwiper1 = new Swiper('.product-use .swiper-container1', {
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            }
        });
        var swiper = new Swiper('.product-use .swiper-container', {
            control: MySwiper1,
            effect: 'fade',
            pagination: {
                el: '.product-use-pagination',
                clickable: true,
                renderBullet: function(index, className) {
                    switch (index) {
                        case 0:
                            text = 'Home';
                            break;
                        case 1:
                            text = 'Store';
                            break;
                        case 2:
                            text = 'Farm';
                            break;
                        case 3:
                            text = 'Warehouse';
                            break;
                    }
                    return '<span class="' + className + '">' + text + '</span>';
                },
            },
        });
        MySwiper1.controller.control = swiper; //Swiper1控制Swiper2，需要在Swiper2初始化后
        swiper.controller.control = MySwiper1; //Swiper2控制Swiper1，需要在Swiper1初始化后

$(function() {
    $(".choose-product").click(function() {
        $(this).addClass("choose_active").siblings().removeClass("choose_active")
    })
})
$(function() {
    $(".choose-product-mobile li").click(function() {
        $(this).addClass("mobile-active").siblings().removeClass("mobile-active")
    })
})
var $item_left = $(".item_left ul li")
var $item_right = $(".item_right ul")
    // $(function() {
    //         $("header").hover(function() {
    //             $(this).addClass("home-header")
    //             $(".header_logo a img", this).attr("src", "https://d2snjavsi8o480.cloudfront.net/wp-content/uploads/2020/07/nav_logo_black@3x.png")
    //         }, function() {
    //             $(this).removeClass("home-header")
    //             $(".header_logo a img", this).attr("src", "https://d2snjavsi8o480.cloudfront.net/wp-content/uploads/2020/07/nav_logo_white@3x.png")
    //         })
    //     })
    // $(function() {
    //     $item_left.hover(function() {
    //         $nav_index = $(this).index()
    //         $("ul.appear").removeClass("fadeIn appear").addClass("fadeOut animated")
    //         $item_right.eq($nav_index).removeClass("fadeOut").addClass("fadeIn animated appear")
    //         $(this).addClass("nav_active").siblings().removeClass("nav_active")
    //     })
    // })
$(".main-nav .menu>li")
$(function() {
    $(".item_top ul li").hover(function() {
        $(this).addClass("nav_active").siblings().removeClass("nav_active")
        var index_nav = $(this).index()
            // console.log(index_nav)
        $(".item_bottom ul").eq(index_nav).addClass("appear").siblings().removeClass("appear")
    })
})
$(function() {
    $("#nav_item1").hover(function() {
        $(".item_content").slideDown(200)
    }, function() {
        $(".item_content").css("display", "none")
    })
})
$(function() {
                var video_items = ["H5S3zTC82aE", "LQjf121E2v4", "jU4ux0dnG2k", "Foe1GSS-7Oo", "iviyPtHLePk"]
                $(".video-clips .swiper-slide").click(function() {
                    var index_video = $(this).attr("data-swiper-slide-index")
                    $(this).addClass("play-icon")
                    $("#youtube-videos").fadeIn()
                    $("#youtube-videos iframe").attr("src", 'https://www.youtube.com/embed/' + video_items[index_video] + '?list=PLK_vkllKvcPH_sE9DUn3vr2iNQmy3PUKJ')
                })
                $("#youtube-videos").click(function() {
                    $(this).fadeOut()
                    $("#youtube-videos iframe").attr("src", ' ')
                })
                $(".video-clips .swiper-slide").hover(function() {
                    $(".title", this).fadeIn()
                }, function() {
                    $(".title", this).fadeOut(100)
                })
            })
